import React from 'react';
import Cursor from '@/Xomponents/Cursor/Cursor';
import './PhraseContainer.css';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { useTextToSpeechStore } from '@/Models/useTextToSpeechStore';

const PhraseContainer = () => {
  const { phrase } = useGestureKeyboardStore();
  const { isSynthesizing } = useTextToSpeechStore();
  return (
    <div className={`phrase-container ${isSynthesizing ? 'running' : ''}`}>
      <h1>{phrase === '' ? 'Escribe un mensaje con tus ojos...' : phrase}</h1>
      <Cursor />
    </div>
  );
};

export default PhraseContainer;
