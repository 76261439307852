import { create } from 'zustand';

export interface UseTextToSpeechStore {
  pending: boolean;
  text: string;
  isSynthesizing: boolean;
  talk: (text: string) => void;
  setIsSynthesizing: (isSynthesizing: boolean) => void;
}

export const useTextToSpeechStore = create<UseTextToSpeechStore>(set => ({
  pending: false,
  text: '',
  isSynthesizing: false,
  talk: (text: string) => set({ text, pending: true }),
  setIsSynthesizing: (isSynthesizing: boolean) => set({ isSynthesizing }),
}));
