import axios from 'axios';
import IUser from '../Models/Types';

export const API_URL = `https://${process.env.REACT_APP_API}`;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const getAxiosInstance = () => {
  return axiosInstance;
};

export const getAuthToken = () => {
  const userToken = localStorage.getItem('user');

  if (userToken) return { authToken: userToken };

  return { authToken: null };
};

export const login = async (email: string, password: string) => {
  const { data } = await axiosInstance.post('/user/login', {
    email,
    password,
  });

  localStorage.setItem('user', data.authToken);
  return data;
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const getCurrentUser = (): IUser | null => {
  const userToken = localStorage.getItem('user');

  if (userToken) return { authToken: userToken };

  return null;
};
