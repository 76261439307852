import { useRef, useState, useEffect } from 'react';
import { FaceLandmarker, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';
import useDetectionStore, { TDetectionConfig } from '@/Models/useDetectionStore';

let detector: FaceLandmarker | null = null;

const loadModel = async (setFaceLandMarkerDetector: (detector: FaceLandmarker) => void, config: TDetectionConfig) => {
  if (detector === null) {
    const vision = await FilesetResolver.forVisionTasks(config.faceLandMark.wasmFileBasePath);
    detector = await FaceLandmarker.createFromOptions(vision, config.faceLandMark.faceLandmarkerOptions);
    setFaceLandMarkerDetector(detector);
  } else {
    setFaceLandMarkerDetector(detector);
  }
};

const useFaceLandMarker = () => {
  const {
    config,
    faceLandMark,
    setFaceLandMarkerDetector,
    setFaceLandMarkerDetectorComplete,
    setFaceLandMarkerDetectorVideo,
    setFaceLandMarkerDetectorResults,
    setFaceLandMarkerDetectorDrawingUtils,
  } = useDetectionStore();

  const webcamRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const modelRef = useRef<FaceLandmarker | null>(null);

  const [lastVideoTime, setLastVideoTime] = useState(0);

  // load model
  useEffect(() => {
    try {
      loadModel(setFaceLandMarkerDetector, config);
      console.log('[STATUS] loadModel');
    } catch (error) {
      console.error('[ERROR] loadModel', error);
    }
  }, []);

  useEffect(() => {
    const context = canvasRef?.current?.getContext('2d');
    if (context) {
      setFaceLandMarkerDetectorDrawingUtils(new DrawingUtils(context));
    }
    console.log('[STATUS] drawigUtils complete');
  }, [canvasRef]);

  useEffect(() => {
    console.log('[STATUS] useFaceLandMarker', config.videoSource.active);
    const hasGetUserMedia = () => {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    };

    const webcamAccess = () => {
      if (hasGetUserMedia()) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(stream => {
            if (webcamRef.current && canvasRef.current) {
              const video = webcamRef.current;
              video.srcObject = stream;
              setFaceLandMarkerDetectorVideo(video);
            }
          })
          .catch(error => {
            console.error('Error al acceder a la cámara:', error);
          });
      } else {
        alert('getUserMedia no es soportado en tu navegador.');
      }
    };

    const videoAccess = () => {
      try {
        if (videoRef.current) {
          setFaceLandMarkerDetectorVideo(videoRef.current);
        }
        console.log('[STATUS] useFacelandMarker videoAccess');
      } catch (error) {
        console.error('[ERROR] useFacelandMarker videoAccess', error);
      }
    };

    const { active } = config.videoSource;

    if (active) {
      videoAccess();
    } else {
      webcamAccess();
    }
  }, [webcamRef, videoRef, config.videoSource]);

  useEffect(() => {
    const { video, detector } = faceLandMark;
    if (video && detector) {
      setFaceLandMarkerDetectorComplete(true);
      predict();
    }
  }, [faceLandMark.video, faceLandMark.detector]);

  const predict = async () => {
    const { video, detector } = faceLandMark;
    try {
      const startTimeMs = performance.now();
      // console.log('[STATUS] predict video', config.videoSource.active);
      if (detector && video && lastVideoTime !== video.currentTime) {
        setLastVideoTime(video.currentTime);
        const results = detector.detectForVideo(video, startTimeMs);
        setFaceLandMarkerDetectorResults(results);
        // console.log('[STATUS] predict', results);
      }
    } catch (error) {
      console.error('[STATUS] Error al detectar marcas faciales en el video:', error);
    }
    window.requestAnimationFrame(predict);
  };

  return { webcamRef, videoRef, canvasRef };
};
export default useFaceLandMarker;
