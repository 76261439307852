import Gestureflag, { GestureflagProps } from '.';
import check from '@/Assets/icons/check.svg';
import useDetectionStore, { Gestures } from '@/Models/useDetectionStore';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';

type IGestureflagProps = {
  gesture: Gestures;
};
const Check = function ({ gesture }: IGestureflagProps) {
  const { confirmation, config } = useGestureConfirmationStore();
  const {
    gestures: { active },
  } = useDetectionStore();

  const props: GestureflagProps = {
    className: 'check',
    active: active[gesture],
    svgUrl: check,
    startTime: confirmation.startTime[gesture],
    timeout: config.timeout[gesture],
  };

  return (
    <div>
      {active[gesture] && <Gestureflag {...props} />}
      {/* <table style={{ borderCollapse: 'collapse', color: 'white', position: 'absolute', top: '-100px', opacity: 0.5 }}>
        <thead>
          <tr>
            <th>{gesture}</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid black' }}>active</td>
            <td style={{ border: '1px solid black' }}>{JSON.stringify(working)}</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid black' }}>progress</td>
            <td style={{ border: '1px solid black' }}>{elapsedPercentage}</td>
          </tr>
          {Object.entries(confirmation).map(([key, value]) => (
            <tr key={key}>
              <td style={{ border: '1px solid black' }}>{key}</td>
              <td style={{ border: '1px solid white' }}>{JSON.stringify(value[gesture])}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default Check;
