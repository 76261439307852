import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Services/AuthService';
import './LogoutButton.css';

interface LogoutButtonProps {
  setCurrentUser: (user: null) => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ setCurrentUser }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    setCurrentUser(null);
    navigate('/login');
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      Logout
    </button>
  );
};

export default LogoutButton;
