import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
// import store from './Redux/Store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  // <Provider store={store}>
  <App />
  // </Provider>
  // </React.StrictMode>
);

reportWebVitals();
