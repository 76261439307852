import React from 'react';
import './GestureFlag.css';
import plainCircle from '../../Assets/icons/plain-circle.svg';

export type GestureflagProps = {
  active?: boolean;
  svgUrl?: string; // URL del SVG for foreground
  startTime?: number; // 0..100
  timeout?: number; // 0..100
  className?: string;
  fill?: boolean;
};

export const calculateElapsedPercentage = (startTime: number, timeout: number): number => {
  const now = Date.now();
  const elapsed = now - startTime;
  const totalDuration = timeout;

  if (elapsed >= totalDuration) {
    return 100;
  }

  const percentage = (elapsed / totalDuration) * 100;
  return Math.min(Math.max(percentage, 0), 100); // Ensure the result is between 0 and 100
};

const Gestureflag: React.FC<GestureflagProps> = ({ svgUrl = '', startTime = 0, timeout = 1000, fill = false, className = '' }) => {
  // const clipPathId = 'asdasd'

  const progress = fill ? 100 : calculateElapsedPercentage(startTime, timeout);

  const calcDashArray = (radius: number, percentage: number) => {
    const circumference = 2 * Math.PI * radius;
    const dashArray = `${(circumference * percentage) / 100} ${circumference}`;
    return dashArray;
  };
  // console.log('CHECK CLASSNAME: ', className)
  return (
    <div className={'gesture-flag ' + className}>
      <svg className="background-svg" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="50" fill="white" opacity="0.1" />
      </svg>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" fill="none" r="25" strokeWidth="50" stroke="#ffffff" strokeDasharray={calcDashArray(25, progress)} />
      </svg>
      <svg className="progress-svg" width="100" height="100" viewBox="0 0 100 100">
        <image href={svgUrl || plainCircle} width="70" height="70" x="15" y="15" />
      </svg>
    </div>
  );
};

export default Gestureflag;
