import React, { useRef, useEffect } from 'react';
import './Detector.css';
import Silhouette from '@/Xomponents/Silhouette/Silhouette';
import useComputeScores from './hooks/useComputeScores';
import useDetectionStore from '@/Models/useDetectionStore';
import { DrawingUtils, FaceLandmarker, NormalizedLandmark } from '@mediapipe/tasks-vision';
import useRangeFiltering from './hooks/useRangeFiltering';
import useFaceLandMarker from '@/Services/useFaceLandMarker';
import pause from '@/Assets/icons/pause-fontawesome.svg';
import play from '@/Assets/icons/play-fontawesome.svg';

// import useConfirmGesture from './hooks/useConfirmGesture';

export const Detector: React.FC = () => {
  const {
    faceLandMark: { detector, result, drawingUtils, video },
    config: { videoSource },
    rangeFiltering: { onRange },
    paused,
    setPaused,
  } = useDetectionStore();

  const { webcamRef, canvasRef, videoRef } = useFaceLandMarker();
  const { normalized, max } = useComputeScores();
  const { boundingBox } = useRangeFiltering();
  // const { } = useConfirmGesture()

  useEffect(() => {
    if (result?.faceLandmarks && drawingUtils && detector) {
      if (canvasRef.current && video) {
        canvasRef.current.width = video.videoWidth;
        canvasRef.current.height = video.videoHeight;
      }
      drawDetection(result.faceLandmarks, drawingUtils, detector, onRange);
    }
  }, [result]);
  const drawDetection = (faceLandmarks: NormalizedLandmark[][], drawingUtils: DrawingUtils, faceLandmarker: FaceLandmarker, isOnRange: boolean) => {
    // console.log("entra a drawDetection",isOnRange)

    const boundingBoxProperties = {
      color: isOnRange ? 'rgba(0,254,20,.2)' : 'rgba(254,20,20,.3)',
      fillColor: 'transparent',
    };
    const baseColor = {
      color: isOnRange ? 'rgba(0,254,20,.2)' : 'rgba(254,20,20,.2)',
      lineWidth: 1,
    };

    const highLightColor = {
      color: isOnRange ? 'rgba(0,184,20,.4)' : 'rgba(184,20,20,.5)',
      lineWidth: 2,
    };

    const solidColor = {
      color: isOnRange ? 'rgba(0,254,20,.9)' : 'rgba(254,20,20,.9)',
      lineWidth: 2,
    };

    if (faceLandmarks && drawingUtils) {
      for (const landmarks of faceLandmarks) {
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LIPS, highLightColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS, solidColor);
        // drawBoundingBox(landmarks)
      }
    }

    // console.log(boundingBox)
    // if (boundingBox !== undefined) {
    //   drawingUtils.drawBoundingBox(boundingBox, boundingBoxProperties);
    // }
  };

  const updateCountRef = useRef(0);
  const fpsRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      fpsRef.current = updateCountRef.current;
      updateCountRef.current = 0;
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    updateCountRef.current++;
  });

  // console.log('[STATUS] onRange', onRange, scores, active, idle);
  return (
    <div className="detectorContainer">
      {/* <h3 className="legend">Mire hacia la izquierda o hacia la derecha para filtrar la letra/comando que desea seleccionar.</h3> */}
      <div className="centerColumn">
        <div className="camContainer">
          {videoSource.active ? (
            <video
              id="video"
              ref={videoRef}
              src={'prueba.mp4'}
              className="cameraFeed"
              autoPlay
              loop
              muted
              style={{
                // position: 'absolute',
                // left: 0,
                // right: 0,
                textAlign: 'center',
                zIndex: 9,
                width: '100%',
                height: 'auto',
                transform: 'scaleX(-1)',
                borderRadius: '10px',
                flex: 1,
              }}
            />
          ) : (
            <video
              id="webcam"
              ref={webcamRef}
              autoPlay
              className="cameraFeed"
              style={{
                // position: 'absolute',
                left: 0,
                right: 0,
                textAlign: 'center',
                zIndex: 9,
                width: '100%',
                height: 'auto',
                transform: 'scaleX(-1)',
                borderRadius: '10px',
                flex: 1,
              }}
            />
          )}

          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              textAlign: 'center',
              zIndex: 9,
              width: '100%',
              transform: 'scaleX(-1)',
              height: 'auto',
            }}
          />
          {/* <button id="webcamButton" className="mdc-button mdc-button--raised">
            <span className="mdc-button__ripple"></span>
            <span className="mdc-button__label">ENABLE WEBCAM</span>
          </button> */}

          <Silhouette />
        </div>

        {/* <button className="pause-button" onClick={() => setPaused(!paused)}>
          <svg width="100" height="100" viewBox="0 0 100 100">
            <image href={paused ? play : pause} x="10" y="10" width="80" height="80" opacity="0.5" />
          </svg>
          {paused ? 'Play' : 'Pause'}
        </button> */}
        {/* <h3 style={{ color: onRange ? 'rgba(100,255,100,1)' : 'rgba(255,255,255,.4)', textTransform: 'capitalize' }}>
          {' '}
          {onRange ? '[ EN RANGO ]' : '[ EN RANGO ]'}
        </h3> */}
        {/* <h3>FPS: {fpsRef.current}</h3> */}
        <div style={{ background: 'white' }}>{/* <GestureCheck scores={normalized} max={max} /> */}</div>
      </div>
      {/* <h3 className="legend">
        <UPlotReact options={opts1} data={convertToTypedArray(typedData as number[][])} />
        Mire hacia arriba para reproducir el texto
      </h3> */}
    </div>
  );
};
