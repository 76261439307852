import React, { useEffect } from 'react';
import './EventCreator.css';
import useDetectionStore, { Gestures, TGestureScoresFlag } from '@/Models/useDetectionStore';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { TGestureConfirmation, useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import { KeyboardGestureEvent } from '@/Models/Constans';

const EventCreator: React.FC = () => {
  const {
    rangeFiltering: { onRange },
    paused,
    gestures,
  } = useDetectionStore();

  const {
    setConfirmation,
    confirmation,
    config: { timeout },
  } = useGestureConfirmationStore();

  const { setKeyboardGesture, currentGesture, oldGesture } = useGestureKeyboardStore();

  const getCandidateEventFor = (flag: Gestures) => {
    let candidateGesture: KeyboardGestureEvent | undefined = undefined;

    if (Gestures.lookUpScore === flag) {
      candidateGesture = KeyboardGestureEvent.LOOK_UP;
    } else if (Gestures.lookLeftScore === flag) {
      candidateGesture = KeyboardGestureEvent.LOOK_LEFT;
    } else if (Gestures.lookRightScore === flag) {
      candidateGesture = KeyboardGestureEvent.LOOK_RIGHT;
    } else if (Gestures.lookDownScore === flag) {
      candidateGesture = KeyboardGestureEvent.LOOK_DOWN;
    }

    return candidateGesture;
  };

  const logTime = (millis = '') => {
    const now = millis ? new Date(millis) : new Date();
    const minutes = `0${now.getMinutes()}`.slice(-2);
    const seconds = `0${now.getSeconds()}`.slice(-2);
    const miliseconds = `00${now.getMilliseconds()}`.slice(-3);
    return `${minutes}:${seconds}:${miliseconds} `;
  };

  const triggerSafe = async (active: TGestureScoresFlag, { completed, running, startTime }: TGestureConfirmation, timeStamp: string) => {
    let candidateGesture: KeyboardGestureEvent | undefined = undefined;

    const outConfirmation: TGestureConfirmation = {
      completed: { ...completed },
      running: { ...running },
      startTime: { ...startTime },
    };

    const currentTime = new Date().getTime();
    const updateGestureConfirmation = (gestureKey: Gestures) => {
      if (active[gestureKey]) {
        // gesture detected
        // console.log('[STATUS] gesture detected:', gestureKey);
        if (running[gestureKey]) {
          // gesture confirmation is running

          if (startTime[gestureKey] + timeout[gestureKey] < currentTime) {
            // gesture confirmation reached timeout
            // console.log('[STATUS] ' + logTime() + ' gesture  COMPLETED:', gestureKey);
            candidateGesture = getCandidateEventFor(gestureKey);
            outConfirmation.startTime[gestureKey] = 0;
            outConfirmation.running[gestureKey] = false;
            outConfirmation.completed[gestureKey] = true;
          } else {
            // gesture confirmation still running
            // console.log('[STATUS] gesture  running:', gestureKey);
          }
        } else {
          // console.log('[STATUS] gesture start running:', gestureKey);
          // gesture is active but confirmation is not running
          outConfirmation.startTime[gestureKey] = currentTime;
          outConfirmation.running[gestureKey] = true;
          outConfirmation.completed[gestureKey] = false;
        }
      } else {
        // gesture not detected
        if (running[gestureKey]) {
          // console.log('[STATUS] gesture not detecte ,stopping', gestureKey);

          // gesture confirmation is running
          outConfirmation.startTime[gestureKey] = 0;
          outConfirmation.running[gestureKey] = false;
          outConfirmation.completed[gestureKey] = false;
        }
      }
    };
    updateGestureConfirmation(Gestures.lookLeftScore);
    updateGestureConfirmation(Gestures.lookRightScore);
    updateGestureConfirmation(Gestures.lookDownScore);
    updateGestureConfirmation(Gestures.lookUpScore);

    setConfirmation(outConfirmation);

    if (oldGesture !== candidateGesture && candidateGesture !== undefined) {
      const currentTime = new Date(timeStamp).getTime();
      const oldTime = oldGesture?.timeStamp ? new Date(oldGesture.timeStamp).getTime() : null;
      if (oldTime === null || currentTime - oldTime >= 1000) {
        // console.log('[STATUS] EventCreator:', oldGesture, candidateGesture);
        setKeyboardGesture({ gesture: candidateGesture, timeStamp });
      }
    }
  };

  useEffect(() => {
    // console.log('gestures idle', gestures.active.lookIdleScore);
    if (onRange && !paused) {
      triggerSafe(gestures.active, confirmation, gestures.scores.timestamp);
    }
  }, [gestures, onRange]);

  // console.log("confirmation", confirmation);
  return <div className="eventCreator"></div>;
};

export default EventCreator;
