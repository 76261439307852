import { useEffect } from 'react';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import useDetectionStore, { Gestures } from '@/Models/useDetectionStore';
import EventCreator from '../EventCreator/EventCreator';
import { Detector } from '../Detector/Detector';
import { KeyboardGestureEvent } from '../../Models/Constans';
import Check from '../GestureFlag/GestureFlag.Check';
import EventsBar from '../EventsBar/EventsBar';
import './GestureKeyboard.css';
// import Cursor from '../Cursor/Cursor';
import { useWordSuggestionStore } from '../../Models/useWordSuggestionStore';

/**
 * Componente principal del teclado de gestos.
 * @returns El componente GestureKeyboard.
 */
const GestureKeyboard = () => {
  const { left, right, phrase, currentGesture, init, pick } = useGestureKeyboardStore();

  const {
    gestures: { scores },
    rangeFiltering: { onRange },
    config: {
      gestureScores: { idleThreshold },
    },
  } = useDetectionStore();

  const { pick: pickPhrase, showSuggestions, left: leftPanel, right: rightPanel } = useWordSuggestionStore();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_LEFT) {
      pickLeft();
    } else if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_RIGHT) {
      pickRight();
    }
  }, [currentGesture]);

  /**
   * Selecciona las teclas y comandos del lado izquierdo.
   */
  const pickLeft = () => {
    if (showSuggestions) {
      pickPhrase(leftPanel.keys, leftPanel.commands);
    } else {
      pick(left.keys, left.commands);
    }
  };

  /**
   * Selecciona las teclas y comandos del lado derecho.
   */
  const pickRight = () => {
    if (showSuggestions) {
      pickPhrase(rightPanel.keys, rightPanel.keys);
    } else {
      pick(right.keys, right.commands);
    }
  };

  /**
   * Resuelve las clases CSS para el lado izquierdo.
   * @returns Las clases CSS correspondientes.
   */
  const resolveLeftSideClasses = () => {
    return scores.lookRightScore > idleThreshold || !onRange ? ' idle' : scores.lookLeftScore > idleThreshold ? ' active' : '';
  };

  /**
   * Resuelve las clases CSS para el lado derecho.
   * @returns Las clases CSS correspondientes.
   */
  const resolveRightSideClasses = () => {
    return scores.lookLeftScore > idleThreshold || !onRange ? ' idle' : scores.lookRightScore > idleThreshold ? ' active' : '';
  };

  const reversedRightSideKeys = [...right.keys];
  // const rightSideKeys = right.commands.concat(reversedRightSideKeys);

  const leftSideKeys = showSuggestions ? leftPanel.commands.concat(leftPanel.keys) : left.commands.concat(left.keys);
  const rightSideKeys = showSuggestions ? rightPanel.commands.concat(rightPanel.keys) : right.commands.concat(reversedRightSideKeys);

  const resolveKeyClasses = (key: string) => {
    return 'keyboard-key' + (key.length == 1 ? ' char' : showSuggestions ? ' phrase' : '');
  };

  // console.log('[STATUS] suggestionsAreActive:', suggestionsAreActive, leftPanel, rightPanel);

  return (
    <div id="gesture-keyboard">
      <div className="keyboard-content">
        {/* <div className="keyboard-heading">
          <h1>{frase === '' ? 'Escribe un mensaje con tus ojos...' : frase}</h1>
          <Cursor frase={frase} />
        </div> */}
        <div>
          {/* <EventsBar /> */}
          <div className="keyboard-sections">
            <div className={'keyboard-section left' + resolveLeftSideClasses()} onClick={() => pickLeft()}>
              <div className="keyboard-keys">
                <Check gesture={Gestures.lookLeftScore} />
                {leftSideKeys.map((key: string) => (
                  <div key={key} className={resolveKeyClasses(key)}>
                    {key}
                  </div>
                ))}
              </div>
            </div>
            <div className="keyboard-section-separator">
              <EventCreator />
              <Detector />
            </div>
            <div className={'keyboard-section right' + resolveRightSideClasses()} onClick={() => pickRight()}>
              <div className="keyboard-keys">
                <Check gesture={Gestures.lookRightScore} />
                {rightSideKeys.map((key: string) => (
                  <div key={key} className={resolveKeyClasses(key)}>
                    <span>{key}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestureKeyboard;
