import React from 'react';
import './Cursor.css';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';

const Cursor = () => {
  const { phrase } = useGestureKeyboardStore();
  const isVisible = phrase !== '';
  const withSpace = phrase.length !== 0 && phrase[phrase.length - 1] !== ' ';

  if (!isVisible) return null;

  return <div className={`cursor${withSpace ? ' with-space' : ''}`}>_</div>;
};

export default Cursor;
