import React from 'react';
import userOutlineGreen from '../../Assets/images/user-outline-green.png';
import userOutlineRed from '../../Assets/images/user-outline-red.png';
import './Silhouette.css';
import useDetectionStore from '@/Models/useDetectionStore';

const Silhouette: React.FC = () => {
  const {
    rangeFiltering: { onRange },
    paused,
    faceLandMark: { video },
  } = useDetectionStore();

  return (
    <img
      style={{
        width: video?.offsetWidth ? video?.offsetWidth + 'px' : '100%',
        height: video?.offsetHeight ? video?.offsetHeight + 'px' : 'auto',
      }}
      src={onRange || !paused ? userOutlineGreen : userOutlineRed}
      className="userSilhouette"
    />
  );
};

export default Silhouette;
