export enum CommandsTypes {
  Restart = 'Reiniciar',
  Space = 'Espacio',
  Enter = 'Enter',
  Delete = 'Borrar',
  DeleteAll = 'Borrar Todo',
}

/**
 * Enum de comandos disponibles.
 */
export enum COMMANDS {
  DELETE = 'BORRAR',
  DELETE_ALL = 'BORRAR TODO',
  SPACE = 'ESPACIO',
  SPEAK = 'PRONUNCIAR',
  RESET = 'REINICIAR',
}

/**
 * Lista de teclas disponibles.
 */
export const KEY_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

/**
 * Lista de comandos disponibles.
 */

export const LEFT_COMMANDS = [COMMANDS.DELETE, COMMANDS.DELETE_ALL];
export const RIGHT_COMMANDS = [COMMANDS.SPACE, COMMANDS.SPEAK, COMMANDS.RESET];

/**
 * Enum de eventos de gestos del teclado.
 */
export enum KeyboardGestureEvent {
  LOOK_LEFT = 'look_left',
  LOOK_RIGHT = 'look_right',
  LOOK_UP = 'look_up',
  LOOK_DOWN = 'look_down',
  LOOK_IDLE = 'look_idle',
}

/**
 * Interfaz que representa un registro de gesto.
 */
export interface IGestureRecord {
  gesture: KeyboardGestureEvent;
  timeStamp: string;
}
