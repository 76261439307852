import { create } from 'zustand';
import { TGestureScoresFlag, TGesstureScores, emptyGestureScoresFlag, emptyGestureScores, Gestures } from './useDetectionStore';

export type TGestureConfirmation = {
  completed: TGestureScoresFlag;
  running: TGestureScoresFlag;
  startTime: TGesstureScores;
};

export interface IGestureConfirmationStore {
  setConfirmation: (confirmation: TGestureConfirmation) => void;
  confirmation: TGestureConfirmation;
  config: {
    timeout: TGesstureScores;
  };

  setCompleted: (completed: TGestureScoresFlag) => void;
}

export const useGestureConfirmationStore = create<IGestureConfirmationStore>((set, get) => ({
  confirmation: {
    completed: { ...emptyGestureScoresFlag },
    running: { ...emptyGestureScoresFlag },
    startTime: { ...emptyGestureScores },
  },

  config: {
    timeout: {
      ...emptyGestureScores,
      lookUpScore: 500,
      lookDownScore: 1000,
      lookLeftScore: 750,
      lookRightScore: 750,
      lookIdleScore: 0,
    },
  },

  setConfirmation: confirmation => set(() => ({ confirmation })),
  setCompleted: (completed: TGestureScoresFlag) => set(state => ({ confirmation: { ...state.confirmation, completed } })),
}));
