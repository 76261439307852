import React, { useEffect } from 'react';
import Gestureflag from '..';
import waveform from '@/Assets/icons/waveform.svg';
import volumeUp from '@/Assets/icons/volume-up.svg';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import useDetectionStore, { Gestures } from '@/Models/useDetectionStore';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { KeyboardGestureEvent } from '@/Models/Constans';
import { useTextToSpeech } from '@/Services/useTextToSpeech';
import './Speak.css';
import useComputeScores from '@/Xomponents/Detector/hooks/useComputeScores';

const Speak: React.FC = function () {
  const { confirmation, config } = useGestureConfirmationStore();

  const { idle } = useComputeScores();

  const { currentGesture, phrase } = useGestureKeyboardStore();
  const { isSynthesizing, handleSynthesis } = useTextToSpeech();

  useEffect(() => {
    if (!isSynthesizing && currentGesture?.gesture === KeyboardGestureEvent.LOOK_UP) {
      handleSynthesis(phrase);
    }
  }, [currentGesture]);

  return (
    <div className={`speak-wrapper${isSynthesizing || !idle.lookUpScore ? ' running' : ''}`}>
      <Gestureflag svgUrl={isSynthesizing ? volumeUp : waveform} startTime={confirmation.startTime[Gestures.lookUpScore]} timeout={config.timeout[Gestures.lookUpScore]} fill={isSynthesizing} />
    </div>
  );
};

export default Speak;
