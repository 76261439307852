import useDetectionStore from '@/Models/useDetectionStore';
import './EventBar.css';
const EventsBar = () => {
  const {
    rangeFiltering: { onRange },
    gestures: {
      scores: { lookLeftScore, lookRightScore, lookUpScore },
    },
    config: {
      gestureScores: { activeThreshold, upScoreThreshold, idleThreshold },
    },
  } = useDetectionStore();

  const leftActivePin = lookLeftScore * 100 + '%';
  const rightActivePin = lookRightScore * 100 + '%';
  const upActivePin = lookUpScore * 100 + '%';
  const activeThresholdPosition = 100 - activeThreshold * 100 + '%';
  const idleThresholdPosition = 100 - idleThreshold * 100 + '%';
  const verticalActiveThresholdPosition = upScoreThreshold * 100 + '%';
  const verticalIdleThresholdPosition = idleThreshold * 100 + '%';
  const rightActiveThreshold = activeThreshold * 100 + '%';
  const rightIdleThreshold = idleThreshold * 100 + '%';

  const leftActivePinProperties = {
    style: { right: leftActivePin },
    className: 'active-pin' + (lookRightScore < idleThreshold && lookLeftScore > idleThreshold ? (lookLeftScore > activeThreshold ? ' enabled' : '') : ' idle'),
  };

  const rightActivePinProperties = {
    style: { left: rightActivePin },
    className: 'active-pin' + (lookLeftScore < idleThreshold && lookRightScore > idleThreshold ? (lookRightScore > activeThreshold ? ' enabled' : '') : ' idle'),
  };

  const upActivePinProperties = {
    style: { bottom: upActivePin },
    className: 'active-pin' + (lookUpScore > idleThreshold ? (lookUpScore > upScoreThreshold ? ' enabled' : '') : ' idle'),
  };

  return (
    <div className="events-bar" style={{ opacity: onRange ? 1 : 0.3 }}>
      <div className="gauge left-event">
        <div className="active-threshold" style={{ left: activeThresholdPosition }}></div>
        <div className="idle-threshold" style={{ left: idleThresholdPosition }}></div>
        <div {...leftActivePinProperties}></div>
      </div>
      <div className="center-gauges">
        <div className="gauge up-event">
          <div className="active-threshold" style={{ bottom: verticalActiveThresholdPosition }}></div>
          <div className="idle-threshold" style={{ bottom: verticalIdleThresholdPosition }}></div>
          <div {...upActivePinProperties}></div>
        </div>
      </div>
      <div className="gauge right-event">
        <div className="active-threshold" style={{ left: rightActiveThreshold }}></div>
        <div className="idle-threshold" style={{ left: rightIdleThreshold }}></div>
        <div {...rightActivePinProperties}></div>
      </div>
    </div>
  );
};

export default EventsBar;
